<!-- System: STA
    Purpose: This compoment will display all team members
            information, details and invite member,
-->
<template>
  <v-app>
    <!-------------------------------- Start Main Section ------------------------------------->
    <v-container fluid class="px-12">
      <!-------------------------------- Start Filter Section ---------------------------------->
      <v-row class="mt-8 mx-auto">
        <div
          v-show="
            activeMembers.length == 1 &&
            activeMembers[0].monthly_minutes == null &&
            archiveMembers.length < 1 && !teamLeadStatus
          "
          class="team-overlay"
        ></div>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="4"
          v-if="selectedCompanyUserStatus != 'employee'"
          class="mt-n3 d-md-flex"
        >
          <!-- Projects Dropdown -->
          <div class="col-sm-and-down-12 text-left mt-2">
            <label class="tm-labels d-flex mb-1"> Search By Project </label>
            <v-autocomplete
              v-model="selectedProject"
              :items="projectList"
              outlined
              dense
              item-text="project_name"
              item-value="id"
            ></v-autocomplete>
          </div>
          <!-- Member Roles Dropdown -->
          <div class="ml-md-2 col-sm-and-down-12 text-left">
            <label class="tm-labels"> Search By Role </label>
            <v-autocomplete
              v-model="search_lead"
              :items="leadDropDown"
              outlined
              dense
              item-text="name"
              item-value="value"
            ></v-autocomplete>
          </div>
          <!-- Deparment list Dropdown -->
          <div class="ml-md-2 col-sm-and-down-12 text-left">
            <label class="tm-labels"> Search By Dept. </label>

            <v-autocomplete
              v-model="department"
              :items="departmentLists"
              outlined
              dense
              item-text="name"
            ></v-autocomplete>
          </div>
        </v-col>
        <!-- Search Bar For Member Name -->
        <v-col
          cols="12"
          sm="6"
          md="2"
          lg="2"
          :offset-md="selectedCompanyUserStatus == 'employee' ? '6' : ''"
          class="mt-n3 text-left"
        >
          <label class="tm-labels"> Search By Name </label>

          <v-text-field
            @input.native="debounceSearch"
            id="search"
            ref="search"
            outlined
            dense
            autocomplete="off"
            color="primary"
            placeholder="Search By Name"
          ></v-text-field>
        </v-col>
        <!-- Export Button -->
        <v-col
          cols="6"
          sm="4"
          md="2"
          lg="1"
          class="mt-5"
          v-if="selectedCompanyUserStatus != 'employee'"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1 mt-n2 white--text tm-btn"
                color="#2758f6"
                dark
                v-bind="attrs"
                v-on="on"
                small
              >
                <span class="mdi mdi-cloud-download mr-2"></span>
                Export
              </v-btn>
            </template>
            <v-list>
              <v-list-item link>
                <v-list-item-title align="left">
                  <DownloadCsv
                    :data="fileDownload.data"
                    :name="json_Datas.details.dataFile"
                    >CSV
                  </DownloadCsv>
                </v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title align="left">
                  <DownloadExcell
                    :data="fileDownload.data"
                    :name="json_Data.details.dataFile"
                    worksheet="abc"
                    >Excel
                  </DownloadExcell>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <!-- Group Monitoring Button -->
        <v-col
          cols="6"
          sm="4"
          md="2"
          lg="2"
          v-if="
            CheckSocketEmployee &&
            checkActiveMembers > 0 &&
            onlineEmployeeCount > 1 &&
            (selectedCompanyUserStatus !== 'employee' || teamLeadStatus > 0)
          "
        >
          <v-btn
            class="mt-3 ml-sm-3 white--text tm-btn"
            color="#2758f6"
            @click="groupMoniter()"
            small
          >
            <span class="mdi mdi-monitor-multiple mr-2"></span>
            <span class="d-none d-lg-flex d-xl-none">Group &#6</span>
            Monitoring
          </v-btn>
        </v-col>
        <v-col v-else class="d-none d-lg-flex d-xl-none"> </v-col>
        <!-- invite Link Button (Component) -->
        <v-col
          cols="6"
          sm="4"
          md="2"
          lg="1"
          class="mt-n1"
          v-if="selectedCompanyUserStatus != 'employee'"
        >
          <InviteLinkModal :new="this.new" :activeMembers="activeMembers" />
        </v-col>
        <!-- Icon For Grid View -->
        <v-col cols="12" sm="4" md="2" lg="2">
          <v-icon
            class="ml-5 mr-5 mt-5 icon-color"
            size="23"
            @click="gridViewSelect()"
            :class="gridView == true ? 'active-list-grid' : ''"
            v-if="selectedCompanyUserStatus != 'employee' || teamLeadStatus > 0"
          >
            mdi-grid
          </v-icon>
          <!-- Icon For List View -->
          <v-icon
            class="ml-2 mt-5 icon-color"
            size="25"
            :class="listView == true ? 'active-list-grid' : ''"
            @click="listViewSelect()"
            v-if="selectedCompanyUserStatus != 'employee' || teamLeadStatus > 0"
          >
            mdi-format-list-bulleted
          </v-icon>
        </v-col>
      </v-row>
      <!-------------------------------- End Filter Section ------------------------------------->
      <!-------------------------------- Start Tab Section  ------------------------------------->
      <v-row>
        <v-col cols="12" sm="6" md="3" lg="2">
          <!-- Team Size Card -->
          <v-card
            outlined
            :color="filter_key === 'total' ? '#2758f6' : ''"
            class="tm-card"
          >
            <v-list-item @click="filter_key = 'total'" class="tm-box">
              <v-card-text
                :class="
                  filter_key === 'total' ? 'white--text' : 'blue-grey--text'
                "
                style="font-size: smaller"
                class="white--text text-left tm-card-text"
              >
                Team Size
              </v-card-text>

              <v-btn
                outlined
                text
                class="tm-card"
                :class="filter_key === 'total' ? 'activeBtn' : 'totalActiveBtn'"
                small
                >{{ employeeActiveCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Present Today Card -->
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-card
            max-height="100"
            max-width="400"
            outlined
            class="tm-card"
            :color="filter_key === 'worked' ? '#2758f6' : ''"
          >
            <v-list-item @click="filter_key = 'worked'" class="tm-box">
              <v-card-text
                style="font-size: smaller"
                :class="filter_key === 'worked' ? 'white--text' : ''"
                class="text-left tm-card-text"
              >
                Present Today
              </v-card-text>
              <v-btn
                outlined
                text
                class="tm-card"
                :class="
                  filter_key === 'worked' ? 'activeBtn' : 'workedActiveBtn'
                "
                small
              >
                {{ employeeWorkedCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Absent Today Card -->
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-card
            max-height="100"
            max-width="400"
            outlined
            class="tm-card"
            :color="filter_key === 'absent' ? '#2758f6' : ''"
          >
            <v-list-item
              class="row-pointer tm-box"
              @click="filter_key = 'absent'"
            >
              <v-card-text
                :class="filter_key === 'absent' ? 'white--text' : ''"
                style="font-size: small"
                class="text-left tm-card-text"
              >
                Absent Today
              </v-card-text>
              <v-btn
                outlined
                text
                class="tm-card"
                :class="
                  filter_key === 'absent' ? 'activeBtn' : 'absentActiveBtn'
                "
                small
              >
                {{ employeeAbsentCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Currently Online Card -->
        <v-col cols="12" sm="6" md="3" lg="2">
          <v-card
            max-height="100"
            max-width="400"
            outlined
            class="text-left tm-card"
            :color="filter_key === 'online' ? '#2758f6' : ''"
          >
            <v-list-item
              class="row-pointer tm-box"
              @click="filter_key = 'online'"
            >
              <v-card-text
                :class="filter_key === 'online' ? 'white--text' : ''"
                style="font-size: small"
                class="tm-card-text"
              >
                Currently Online
              </v-card-text>
              <v-btn
                outlined
                text
                class="tm-card"
                :class="
                  filter_key === 'online' ? 'activeBtn' : 'onlineActiveBtn'
                "
                small
              >
                {{ onlineEmployeeCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Invited Members Card -->
        <v-col
          cols="12"
          md="3"
          lg="2"
          sm="6"
          v-if="selectedCompanyUserStatus != 'employee'"
        >
          <v-card
            max-height="100"
            max-width="400"
            outlined
            class="tm-card"
            :color="filter_key === 'invited' ? '#2758f6' : ''"
          >
            <v-list-item @click="filter_key = 'invited'" class="tm-box">
              <v-card-text
                :class="filter_key === 'invited' ? 'white--text' : ''"
                style="font-size: small"
                class="tm-card-text"
              >
                Invited Members
              </v-card-text>
              <v-btn
                outlined
                text
                class="tm-card"
                :class="
                  filter_key === 'invited' ? 'activeBtn' : 'invitedActiveBtn'
                "
                small
              >
                {{ employeeInvitedCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
        <!-- Archived Members Card -->
        <v-col
          ccols="12"
          md="3"
          lg="2"
          sm="6"
          v-if="selectedCompanyUserStatus != 'employee'"
        >
          <v-card
            max-height="100"
            max-width="400"
            outlined
            class="tm-card"
            :color="filter_key === 'archive' ? '#2758f6' : ''"
          >
            <v-list-item @click="filter_key = 'archive'" class="tm-box">
              <v-card-text
                :class="filter_key === 'archive' ? 'white--text' : ''"
                style="font-size: small"
                class="tm-card-text"
              >
                Archived Members
              </v-card-text>
              <v-btn
                outlined
                text
                small
                class="tm-card"
                :class="
                  filter_key === 'archive' ? 'activeBtn' : 'archiveActiveBtn'
                "
              >
                {{ employeeArchiveCount }}
              </v-btn>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <!-------------------------------- End Tab Section  --------------------------------------->
      <!-------------------------------- Start Import Components Section  ----------------------->
      <div v-if="enterTeamMembers">
        <TeamMemberTableView
          :members="currentPageMembersNew"
          v-if="filter_key !== 'archive' && listView === true"
        />
        <TeamMemberArchiveTableView
          :members="currentPageMembers"
          v-if="filter_key === 'archive' && listView === true"
        />
        <TeamMemberGridView
          :members="currentPageMembersNew"
          v-if="filter_key != 'archive' && gridView === true"
        />
        <TeamMemberArchiveGridView
          :members="currentPageMembers"
          v-if="filter_key == 'archive' && gridView === true"
        />
        <EditUserInfo />
        <AssignTeamMemberModal />
        <AssignProjectModal />
        <InstantCaptureModal />
        <RealTimeMonitorModal />
        <FaceRegistrationTeamMember />
      </div>
      <!-------------------------------- End Import Components Section  ------------------------->
    </v-container>
    <!-------------------------------- End Main Section   ----------------------->
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import JsonExcel from "vue-json-excel";
import JsonCSV from "vue-json-csv";
export default {
  name: "TeamMembers",
  components: {
    TeamMemberTableView: () =>
      import("../components/TeamMembers/TeamMemberTableView"),
    TeamMemberArchiveTableView: () =>
      import("../components/TeamMembers/TeamMemberArchiveTableView"),
    InviteLinkModal: () => import("@/components/TeamMembers/InviteLinkModal"),
    TeamMemberGridView: () =>
      import("../components/TeamMembers/TeamMemberGridView"),
    TeamMemberArchiveGridView: () =>
      import("../components/TeamMembers/TeamMemberArchiveGridView"),
    EditUserInfo: () => import("@/components/TeamMembers/EditUserInfoModal"),
    AssignTeamMemberModal: () =>
      import("@/components/TeamMembers/AssignTeamMemberModal"),
    AssignProjectModal: () =>
      import("@/components/TeamMembers/AssignProjectModal"),
    InstantCaptureModal: () =>
      import("@/components/TeamMembers/InstantCaptureModal"),
    RealTimeMonitorModal: () =>
      import("@/components/TeamMembers/RealTimeMonitorModal"),
    FaceRegistrationTeamMember: () =>
      import("@/components/TeamMembers/FaceRegistrationTeamMember"),
    DownloadExcell: JsonExcel,
    DownloadCsv: JsonCSV,
  },
  data() {
    return {
      enterTeamMembers: false,
      enterTeamMember: false,
      firstTime: true,
      filter_key: "total",
      new: "",
      search_lead: "ALL",
      selectedProject: "All",
      department: "All",
      isHidden: false,
      isActive2: false,
      isActive3: false,
      currentShowingMembers: [],
      isAdmin: "",
      AddPayable: "",
      currentPage: 1,
      search: "",
      team_members_length: 0,
      isActive: true,
      is_loader: true,
      workingProject: "all",
      invite_link: null,
      sort_projects: [],
      checkTimeMembers: false,
      listView: true,
      gridView: false,
      leadDropDown: [
        { value: "ALL", name: "All" },
        { value: "TEAMLEAD", name: "Team Lead" },
        { value: "ADMIN", name: "Admin" },
      ],
      employees_count: {
        all: 0,
        active: 0,
        archived: 0,
        absent: 0,
        invited: 0,
      },
      json_Data: {
        details: {
          labels: {
            srno: "Sr. No",
            members: "Members",
            projects: "Projects",
            designation: "Designation",
            hoursWorked: "Hours Worked",
            addPayable: "Add Payable",
          },
          fields: [
            "srno",
            "members",
            "projects",
            "designation",
            "hoursWorked",
            "addPayable",
          ],
          dataFile:
            "Team Members Record (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").xls",
        },
      },
      json_Datas: {
        details: {
          labels: {
            srno: "Sr. No",
            members: "Members",
            projects: "Projects",
            designation: "Designation",
            hoursWorked: "Hours Worked",
            addPayable: "Add Payable",
          },

          fields: [
            "srno",
            "members",
            "projects",
            "designation",
            "hoursWorked",
            "addPayable",
          ],
          dataFile:
            "Team Members Record (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").csv",
        },
      },
    };
  },
  computed: {
    ...mapState("custom", [
      "projects_list",
      "team_members",
      "active_team_members_list",
      "archived_team_members_list",
      "selected_company",
      "dashboard_data",
      "companies_list",
      "all_store_socket_employee",
      "all_store_socket_projects",
      "company_department_list",
      "team_members_projects_list",
      "lead_team_members",
    ]),
    /**
     * Return the status of
     * is team lead
     */
    teamLeadStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
    },
    /**
     * Return the custom
     * deparment list
     */
    departmentLists() {
      let arrayDep = [];
      let allTeamMembers = {
        name: "All",
      };
      arrayDep.push(allTeamMembers);
      if (this.company_department_list.length >= 1) {
        this.company_department_list.map((department) => {
          let newDepartment = {
            hash_id: department.hash_id,
            name: department.name,
          };
          arrayDep.push(newDepartment);
        });
      }
      return arrayDep;
    },
    /**
     * Return the custom
     * project list
     */
    projectList() {
      let arrayPro = [];
      let allproject = {
        id: "All",
        project_name: "All",
      };
      arrayPro.push(allproject);
      this.projects_list.active.map((project) => {
        let newProject = {
          id: project.id,
          project_name: project.project_name,
        };
        arrayPro.push(newProject);
      });
      return arrayPro;
    },
    currentPageMembersNew() {
      return this.currentPageMembersMethod();
    },
    /**
     * Return the number of active
     * members
     */
    employeeActiveCount() {
      let members;
      members = this.active_team_members_list;
      let membersFilter = this.getEmployeeCount(members, "all");
      return membersFilter.length;
    },
    /**
     * Return the number of absent
     * members
     */
    employeeAbsentCount() {
      let members;
      members = this.active_team_members_list;
      let membersFilter = this.getEmployeeCount(members, "absent");
      return membersFilter.length;
    },
    /**
     * Return the online members
     */
    total_online_employees() {
      let member = this.socket_employee.filter(
        (member) => member.task_running === true
      );
      return member;
    },
    /**
     * Return the number of invited
     * members
     */
    employeeInvitedCount() {
      let members;
      members = this.active_team_members_list;
      let membersFilter = this.getEmployeeCount(members, "invited");
      return membersFilter.length;
    },
    /**
     * Return the number of archived
     * members
     */
    employeeArchiveCount() {
      let members;
      members = this.archiveMembers;
      let membersFilter = this.getEmployeeCount(members, "archive");
      return membersFilter.length;
    },
    /**
     * Return the number of present
     * members
     */
    employeeWorkedCount() {
      let members;
      members = this.worked_employees;
      let membersFilter = this.getEmployeeCount(members, "worked");
      return membersFilter.length;
    },
    /**
     * Return the number of online
     * members
     */
    onlineEmployeeCount() {
      let members;
      members = this.active_team_members_list;
      let membersFilter = this.getEmployeeCount(members, "online");
      return membersFilter.length;
    },
    /**
     * Return the worked members
     */
    worked_employees() {
      let members = this.activeMembers.filter(
        (member) => parseInt(member.today_minutes) && member.is_active !== null
      );
      return members;
    },
    /**
     * Return the absent members
     */
    absent_employees() {
      let member = this.activeMembers.filter(
        (member) =>
          !parseInt(member.today_minutes) &&
          member.is_active !== null &&
          !this.onlineMembersIds.includes(member.id)
      );
      return member;
    },
    /**
     * Return the online member ids
     */
    onlineMembersIds() {
      let ids = [];
      for (let member of this.total_online_employees) {
        ids.push(member.user_id);
      }
      return ids;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    /**
     * Return the active projects
     */
    active_projects() {
      return this.projects_list.active;
    },
    check_time() {
      if (this.active_team_members_list.length > 0) {
        let member_list = this.active_team_members_list.filter(
          (member) =>
            member.monthly_minutes !== null ||
            member.today_minutes !== 0 ||
            member.weekly_minutes !== null
        );
        let query_member = member_list.length;
        if (query_member > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    // Download File From JSON
    fileDownload() {
      return {
        data: this.detailsFileJson,
        name: this.json_Data.details.dataFile,
        labels: this.json_Data.details.labels,
        fields: this.json_Data.details.fields,
      };
    },
    invited_members() {
      return this.active_team_members_list.filter(
        (member) => member.is_active === null
      );
    },
    detailsFileJson: function () {
      let rawdata = this.activeMembers;
      let fileJson = [];
      let i = 1;
      var memberProject;
      let loop = 0;
      rawdata.forEach((teamMember) => {
        if (teamMember.hasOwnProperty("projects_count")) {
          let newTeamMember = this.team_members.find((team_member) => {
            return team_member.id === teamMember.id;
          });
          let memberProjects = [];
          if (newTeamMember && newTeamMember.projects) {
            newTeamMember.projects.forEach((project) => {
              memberProjects.push(project.project_name);
            });
          }
          memberProjects = memberProjects.toString();
          memberProjects.split(", ");
          memberProject = memberProjects;
        }

        let isAdmin = teamMember.is_secondary_user
          ? teamMember.is_secondary_user === 0
            ? "No"
            : "Yes"
          : "No";

        if (rawdata || this.socket_employee) {
          for (let x = 0; x < this.socket_employee.length; x++) {
            if (this.socket_employee[x].user_id == rawdata[loop].id) {
              memberProject = this.socket_employee[x].project;
            }
          }
        }
        let convertedMonthlyMinutes = this.timeConvert(
          teamMember.monthly_minutes
        );
        let convertedWeeklyMinutes = this.timeConvert(
          teamMember.weekly_minutes
        );
        let convertedTodaysMinutes = this.timeConvert(teamMember.today_minutes);

        let data = {
          "Sr. No": i,
          Members: teamMember.name,
          Projects: memberProject,
          Designation: teamMember.designation,
          "Today's Hours": convertedTodaysMinutes,
          "Weekly Hours": convertedWeeklyMinutes,
          "Monthly Hours": convertedMonthlyMinutes,
          Admin: isAdmin,
        };
        fileJson.push(data);
        i++;
        loop++;
        memberProject = "";
      });
      return fileJson;
    },
    /**
     * Return the current page member
     */
    current_page_members() {
      let members = [];
      this.active_team_members_list.forEach((member) => {
        if (
          member &&
          member !== null &&
          member.name &&
          member.name.toLowerCase().includes(this.search.toLowerCase())
        ) {
          members.push(member);
        }
      });
      this.set_member_length(members.length);
      return members.slice(this.currentPage * 8 - 8, this.currentPage * 8);
    },
    /**
     * Return the selected Company
     */
    selectedCompany() {
      return this.$store.state.custom.selected_company;
    },
    /**
     * Return the list of socket employess
     */
    socket_employee() {
      return this.$store.state.custom.all_store_socket_employee;
    },
    /**
     * Return the list current page member
     */
    currentPageMembers() {
      this.search = this.search.trimStart();
      let members;
      if (this.department !== "All") {
        members = this.filteredMembers().filter(
          (member) => member.department_name == this.department
        );
      } else {
        members = this.filteredMembers();
      }
      members = members
        .filter((member) => {
          let bool =
            (member.name !== null &&
              member.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (member.designation !== null &&
              member.designation
                .toLowerCase()
                .includes(this.search.toLowerCase()));
          if (this.search_lead !== "ALL") {
            if (this.search_lead === "ADMIN") {
              bool =
                member.admin !== null &&
                member.admin.includes(this.search_lead) &&
                member.name.toLowerCase().includes(this.search.toLowerCase());
            }
            if (this.search_lead === "SUPERSECUREUSER") {
              bool =
                member.super_secure_user !== null &&
                member.super_secure_user.includes(this.search_lead) &&
                member.name.toLowerCase().includes(this.search.toLowerCase());
            }
            if (this.search_lead == "TEAMLEAD") {
              bool =
                member.team_lead_members !== null &&
                member.super_secure_user !== "SUPERSECUREUSER" &&
                member.admin !== "ADMIN" &&
                member.team_lead_members.includes(this.search_lead) &&
                member.name.toLowerCase().includes(this.search.toLowerCase());
            }
          }
          return bool;
        })
        .sort((a, b) => {
          let x = a.today_minutes ? parseInt(a.today_minutes) : 0;
          let y = b.today_minutes ? parseInt(b.today_minutes) : 0;
          return x < y ? 1 : x > y ? -1 : 0;
        });
      if (this.enterTeamMember == false) {
        members = members.slice(0, 30);
      }
      return members;
    },
    /**
     * Return the list of active members
     */
    activeMembers() {
      const activeMembers = this.active_team_members_list;
      let members =
        this.workingProject === "all"
          ? activeMembers
          : activeMembers.filter((mem) =>
              this.socket_employee
                .filter((member) => member.project === this.workingProject)
                .find((member) => member.user_id === mem.id)
            );
      return members;
    },
    /**
     * Return the list of archive members
     */
    archiveMembers() {
      return this.archived_team_members_list;
    },
    /**
     * Return true if socket employee
     * length is 1 or greater
     */
    CheckSocketEmployee() {
      if (
        this.all_store_socket_employee &&
        this.all_store_socket_employee.length >= 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    checkActiveMembers() {
      return this.active_team_members_list.length;
    },
  },
  watch: {
    selectedProject: function () {
      if (this.selectedProject != "All") {
        let company_id = this.$route.params.id;
        let search_data = {
          project: this.selectedProject,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        this.$store
          .dispatch("custom/get_active_team_members_list", {
            company_id,
            search_data,
          })
          .then(() => {})
          .catch(() => {});
      } else {
        let company_id = this.$route.params.id;
        let search_data = {
          project: null,
        };
        this.$store.dispatch("custom/get_active_team_members_list", {
          company_id,
          search_data,
        });
      }
    },
  },
  mounted() {
    setTimeout(() => {
      let onlineEmployee = this.$store.state.custom.all_store_socket_employee.filter(
        (member) => member.task_running === true
      );
      if (onlineEmployee.length > 0) {
        this.filter_key = "online";
      } else {
        this.filter_key = "total";
      }
      this.enterTeamMember = true;
    }, 1000);
    setTimeout(() => {
      this.enterTeamMember = true;
    }, 500);
    setTimeout(() => {
      this.enterTeamMembers = true;
    }, 100);
    if (
      this.team_members.length < 1 &&
      (this.selectedCompanyUserStatus !== "employee" ||
        this.selected_company.has_team != 0)
    ) {
      this.$store.dispatch("custom/get_team_members", {
        company_id: this.$route.params.id,
      });
    }
  },
  created() {
    this.check_if_from_set_password_page();
    this.get_team_members_list();
    if (
      this.selectedCompanyUserStatus !== "employee" &&
      this.company_department_list.length < 1
    ) {
      this.get_department_list();
    }
    this.getProjects();
  },
  methods: {
    check_if_from_set_password_page() {
      if (this.$route.query.new === "yes") {
        this.new = "yes";
      }
    },
    //New Section Start
    listViewSelect() {
      this.listView = true;
      this.gridView = false;
    },
    /**
     * This function is resposible for displaying
     * grid view table
     */
    gridViewSelect() {
      this.listView = false;
      this.gridView = true;
    },
    /**
     * This function is resposible for getting
     * the list of active team members
     */
    get_team_members_list() {
      this.$store.commit("custom/toggle_loader", true);
      let company_id = this.$route.params.id;
      let search_data = {
        project: null,
      };
      this.$store.dispatch("custom/get_active_team_members_list", {
        company_id,
        search_data,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },
    currentPageMembersMethod() {
      let local_active_team_members_list = [...this.active_team_members_list];
      local_active_team_members_list.map((mem) => {
        mem.display = false;
      });
      local_active_team_members_list.map((mem) => {
        for (let member of this.currentPageMembers) {
          if (mem.id == member.id) {
            member.display = true;
          }
        }
      });
      return local_active_team_members_list;
    },

    filterMembers(members, key) {
      let active_members = this.activeMembers.filter(
        (members) => members.member_status === "active"
      );
      return active_members.filter((member) =>
        members.find((mem) => member.id === mem[key])
      );
    },
    filteredMembers() {
      switch (this.filter_key) {
        case "total":
          return this.activeMembers.filter(
            (members) => members.member_status === "active"
          );
        case "online":
          return this.filterMembers(this.total_online_employees, "user_id");
        case "archive":
          return this.archiveMembers;
        // return this.activeMembers.filter( members => members.member_status === "archive");
        // return this.filterMembers(this.total_idle_employees, "user_id");
        case "absent":
          return this.filterMembers(this.absent_employees, "id");
        case "offline":
          return this.total_offline_employees;
        case "worked":
          return this.worked_employees;
        default:
          return this.filterMembers(this.invited_members, "id");
      }
    },
    /**
     * This function is resposible for sorting
     * or displaying team members according to the
     * filter key
     */
    getEmployeeCount(membersData, filterKey) {
      let activeMembers = membersData;
      let members;
      if (this.department !== "All") {
        members = activeMembers.filter(
          (member) => member.department_name == this.department
        );
      } else {
        members = activeMembers;
      }
      members = members.filter((member) => {
        let bool =
          (member.name !== null &&
            member.name.toLowerCase().includes(this.search.toLowerCase())) ||
          (member.designation !== null &&
            member.designation
              .toLowerCase()
              .includes(this.search.toLowerCase()));
        if (filterKey !== "archive") {
          if (this.search_lead !== "ALL") {
            if (this.search_lead === "ADMIN") {
              bool =
                member.admin !== null &&
                member.admin.includes(this.search_lead);
            }
            if (this.search_lead === "SUPERSECUREUSER") {
              bool =
                member.super_secure_user !== null &&
                member.super_secure_user.includes(this.search_lead);
            }
            if (this.search_lead == "TEAMLEAD") {
              bool =
                member.team_lead_members !== null &&
                member.super_secure_user !== "SUPERSECUREUSER" &&
                member.admin !== "ADMIN" &&
                member.team_lead_members.includes(this.search_lead);
            }
          }
        }
        return bool;
      });

      if (filterKey === "invited") {
        members = members.filter((member) => member.is_active == null);
      }
      if (filterKey === "archive") {
        members = members.filter((member) => member.member_status !== "active");
      }
      if (filterKey === "online") {
        let key = "user_id";
        let onlineMemebrs = this.total_online_employees;
        return members.filter((member) =>
          onlineMemebrs.find((mem) => member.id === mem[key])
        );
      }
      if (filterKey === "absent") {
        let key = "id";
        let onlineMemebrs = this.absent_employees;
        return members.filter((member) =>
          onlineMemebrs.find((mem) => member.id === mem[key])
        );
      }
      return members;
    },
    /**
     * This function is resposible for
     * convertion of time format
     */
    timeConvert(n) {
      let num = n;
      let hours = num / 60;
      let rhours = Math.floor(hours);
      let minutes = (hours - rhours) * 60;
      let rminutes = Math.round(minutes);
      return rhours + ":" + rminutes;
    },
    /**
     * This function is resposible for getting
     * the list of deparments
     */
    get_department_list() {
      let data = {
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/getDeaprtmentList", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.departmentList = _.orderBy(
            response.data.department,
            ["name"],
            ["asc"]
          );
          this.$store.commit(
            "custom/set_company_department_list",
            _.orderBy(response.data.department, ["name"], ["asc"])
          );
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response) {
            if (error.response.data.message === "Unauthenticated.") {
              this.$store.commit("custom/resetState");
              this.$store.dispatch("auth/reset").then(() => {
                return this.$router.push({ name: "login" });
              });
            }
          }
        });
    },
    /**
     * This function is resposible for getting
     * the list of projects
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },
    set_member_length(members_length) {
      this.team_members_length = members_length;
    },
    /**
     * This function is resposible for group
     * monitoring of members
     */
    groupMoniter() {
      let filterOnlineMembers;
      let Onlineemployees = [];
      let currentMembers = [];
      Onlineemployees = this.$store.state.custom.all_store_socket_employee;
      // currentMembers      = this.$store.state.custom.filter_active_team_members_list;
      currentMembers = this.filteredMembersBySorting();
      let groupEmployee = [];
      Onlineemployees.map((data) => {
        groupEmployee.push(data.user_id);
      });
      filterOnlineMembers = currentMembers.filter((memberOnline) =>
        groupEmployee.includes(memberOnline.id)
      );
      filterOnlineMembers = filterOnlineMembers.map((m) => ({
        ...m,
        image_status: false,
      }));
      filterOnlineMembers.find((fom) => {
        Onlineemployees.find((om) => {
          if (fom.id == om.user_id) fom.project_name = om.project;
        });
      });
      let selectedProject = this.getSelectedProject();
      if (selectedProject) {
        filterOnlineMembers = filterOnlineMembers.filter(
          (members) => members.project_name === selectedProject.project_name
        );
      }
      let projectCheck = this.projectList.find(
        (data) => data.id == this.selectedProject
      );
      let selectedFilterKey = {
        project: projectCheck ? projectCheck.project_name : "All",
        department: this.department,
        search_by_name: this.search == "" ? "ALL" : this.search,
        search_by_lead: this.search_lead,
      };
      this.$store.commit("custom/set_member_filter_key", selectedFilterKey);
      this.$store.commit("custom/group_montring", filterOnlineMembers);
      let routeData = this.$router.resolve({ name: "members-group-monitor" });
      window.open(routeData.href, "_blank");
    },
    /**
     * This function is resposible for returning
     * selected project for group monitoring
     */
    getSelectedProject() {
      let project_list = this.$store.state.custom.team_members_projects_list;
      return project_list.find((project) => project.id == this.selectedProject);
    },
    debounceSearch: _.debounce(function (e) {
      this.search = e.target.value;
    }, 200),
    /**
     * This function is resposible for sorting
     * member for group monitoring
     */
    filteredMembersBySorting() {
      this.search = this.search.trimStart();
      let members;
      if (this.department !== "All") {
        members = this.filteredMembers().filter(
          (member) => member.department_name == this.department
        );
        console.log("Department Members", members);
      } else {
        members = this.filteredMembers();
      }
      members = members
        .filter((member) => {
          let bool =
            (member.name !== null &&
              member.name.toLowerCase().includes(this.search.toLowerCase())) ||
            (member.designation !== null &&
              member.designation
                .toLowerCase()
                .includes(this.search.toLowerCase()));
          if (this.search_lead !== "ALL") {
            if (this.search_lead === "ADMIN") {
              bool =
                member.admin !== null &&
                member.admin.includes(this.search_lead);
            }
            if (this.search_lead === "SUPERSECUREUSER") {
              bool =
                member.super_secure_user !== null &&
                member.super_secure_user.includes(this.search_lead);
            }
            if (this.search_lead == "TEAMLEAD") {
              bool =
                member.team_lead_members !== null &&
                member.super_secure_user !== "SUPERSECUREUSER" &&
                member.admin !== "ADMIN" &&
                member.team_lead_members.includes(this.search_lead);
            }
          }
          //date_online_members
          if (["online", "offline"].includes(this.member_sort)) {
            let check = !!this.socket_employee.find(
              (user) => user.user_id === member.id
            );
            return (
              bool &&
              ((check && this.member_sort === "online") ||
                (!check && this.member_sort === "offline"))
            );
          }
          return bool;
        })
        .sort((a, b) => {
          let x = a.name.toLowerCase();
          let y = b.name.toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });
      return members;
    },
  },
};
</script>
<style scoped>
.tm-card {
  box-sizing: border-box;
  border: 1px solid #cbdaf2;
  border-radius: 20px;
}
.tm-box:hover {
  background: transparent !important;
}
.tm-labels {
  height: 12px;
  width: 88px;
  color: #36454f;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
}

.tm-btn {
  height: 40px !important;
  border-radius: 4px !important;
  background-color: #2758f6 !important;
}
.team-overlay {
  position: fixed;
  z-index: 1;
  background: #000;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
}
.tm-box {
  padding: 0 10px !important;
}
.tm-card-text {
  padding: 0 !important;
}
</style>
<style>
.error-img {
  height: 250px;
  width: 300px;
  margin-top: 30px;
}
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
}
.activeBtn {
  background: white !important;
  color: black !important;
}
.totalActiveBtn {
  background: #2758f6;
  color: white !important;
}
.workedActiveBtn {
  background: #17a2b8;
  color: white !important;
}
.absentActiveBtn {
  background: rgb(255, 95, 95);
  color: white !important;
}
.onlineActiveBtn {
  background: rgb(74, 207, 139);
  color: white !important;
}
.invitedActiveBtn {
  background: rgb(247, 177, 46);
  color: white !important;
}
.archiveActiveBtn {
  background: rgb(141, 171, 221);
  color: white !important;
}
.activeText {
  color: white !important;
}
.active-list-grid {
  color: #2858f6 !important;
}
</style>
